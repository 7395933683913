








import { TimePicker } from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import format from "date-fns/format";
import VueTimepicker from "vue2-timepicker";

const ConceptOpeningHourseScheduleTimeProps = Vue.extend({
  props: {
    time: {
      type: String,
      default: "",
    },
    value: String
  },
});
@Component({
  components: {
    VueTimepicker,
  },
})
export default class ConceptOpeningHourseScheduleTime extends ConceptOpeningHourseScheduleTimeProps {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }
}
